export default {
    menu: {
        pos: {
            icon: "pos.png",
            label: "POS",
            link: "../../../pos/login.html?redirect=admin",
            labelLangs: {
                ar: "نقاط البيع",
                en: "POS",
                zh: "收银机",
                de: "Pos",
                iw: "קופה",
                it: "POS",
                ro: "POS",
                es: "TPV",
                tr: "satış noktası",
                fr: "PDV",
                th: "เครื่องขายหน้าร้าน"
            }
        },
        dashboard: {
            icon: "dashboard.png",
            label: "Dashboard",
            link: "sections/main-section/reports.html",
            id: "sectionDashboardIframe",
            labelLangs: {
                ar: "لوحة القيادة",
                en: "Dashboard",
                zh: "仪表盘",
                de: "Armaturenbrett",
                iw: "לוּחַ מַחווָנִים",
                it: "Pannello",
                ro: "Bord",
                es: "Tablero",
                tr: "Gösterge Paneli",
                fr: "Tableau de bord",
                th: "แผงควบคุม"
            }
        },
        locations: {
            icon: "locations.png",
            label: "Locations",
            link: "sections/main-section/location-admin.html",
            id: "sectionLocationsIframe",
            labelLangs: {
                ar: "المواقع",
                en: "Locations",
                zh: "地点",
                de: "Standorte",
                iw: "מיקומים",
                it: "Luoghi",
                ro: "Locații",
                es: "Ubicaciones",
                tr: "konumlar",
                fr: "Emplacements",
                th: "สถานที่"
            }
        },
        customers: {
            icon: "customers.png",
            label: "Customers",
            link: "sections/customers-marketing/index.html",
            id: "sectionCustomerIframe",
            labelLangs: {
                ar: "عملاء",
                en: "Customers",
                zh: "顾客",
                de: "Kunden",
                iw: "לקוחות",
                it: "Clienti",
                ro: "Clienți",
                es: "Clientes",
                tr: "Müşteriler",
                fr: "Les clients",
                th: "ลูกค้า"
            }
        },
        employees: {
            icon: "employees.png",
            label: "Employees",
            link: "sections/manage-employee/index.html",
            id: "sectionEmployeesIframe",
            labelLangs: {
                ar: "الموظفين",
                en: "Employees",
                zh: "雇员",
                de: "Angestellte",
                iw: "עובדים",
                it: "Dipendenti",
                ro: "Angajatii",
                es: "Empleados",
                tr: "Çalışanlar",
                fr: "Employés",
                th: "พนักงาน"
            }
        },
        inventory: {
            icon: "inventory.png",
            label: "Inventory",
            link: "sections/inventory/index.html",
            id: "sectionInventoryIframe",
            labelLangs: {
                ar: "جرد",
                en: "Inventory",
                zh: "存货",
                de: "Inventar",
                iw: "מְלַאי",
                it: "Inventario",
                ro: "Inventar",
                es: "Inventario",
                tr: "Envanter",
                fr: "Inventaire",
                th: "รายการสิ่งของ"
            }
        },
        accounting: {
            icon: "reports.png",
            label: "Reports",
            link: "sections/administration/index.html",
            id: "sectionAccountingIframe",
            labelLangs: {
                ar: "التقارير",
                en: "Reports",
                zh: "报告",
                de: "Berichte",
                iw: "דיווחים",
                it: "Rapporti",
                ro: "Rapoarte",
                es: "Informes",
                tr: "Raporlar",
                fr: "Rapports",
                th: "รายงาน"
            }
        },
        appointment: {
            icon: "appointments.png",
            label: "Appointments",
            link: "sections/calendar/appointmentsadmin.html",
            id: "sectionAppointmentIframe",
            labelLangs: {
                ar: "تعيينات",
                en: "Appointments",
                zh: "约会",
                de: "Termine",
                iw: "פגישות",
                it: "Appuntamenti",
                ro: "Rezervări",
                es: "Equipo",
                tr: "Randevular",
                fr: "Rendez-vous",
                th: "การนัดหมาย"
            }
        },
        onlinestore: {
            icon: "kicker-logo-short.png",
            label: "Kicker Point",
            // link: "https://kickerpoint.web.app/admin/index.html?rf=sp",
            link: "sections/kicker-point/index.html?rf=spiframe",
            id: "sectionKickerPointIframe",
            labelLangs: {
                ar: "كيكر بوينت",
                en: "Kicker Point",
                zh: "踢脚点",
                de: "Kickerpunkt",
                iw: "קיקר פוינט",
                it: "Punto Kicker",
                ro: "Kicker Point",
                es: "punto de pateador",
                tr: "Vuruş Noktası",
                fr: "Point de départ",
                th: "คิกเกอร์พอยท์"
            }
        },
        myaccount: {
            icon: "myaccount.png",
            label: "My Account",
            link: "sections/main-section/my-account.html",
            resolution: "fit",
            id: "sectionMyAccountIframe",
            labelLangs: {
                ar: "حسابي",
                en: "My Account",
                zh: "我的账户",
                de: "Mein Konto",
                iw: "החשבון שלי",
                it: "Il mio conto",
                ro: "Contul meu",
                es: "Mi cuenta",
                tr: "Hesabım",
                fr: "Mon compte",
                th: "บัญชีของฉัน"
            }
        },
        feedback: {
            icon: "feedback.png",
            label: "Feedback",
            link: "sections/feedback/index.html",
            id: "sectionFeedbackIframe",
            labelLangs: {
                ar: "ردود الفعل",
                en: "Feedback",
                zh: "回馈",
                de: "Rückmeldung",
                iw: "מָשׁוֹב",
                it: "Feedback",
                ro: "Părere",
                es: "Realimentación",
                tr: "Geri bildirim",
                fr: "Retour",
                th: "ข้อเสนอแนะ"
            }
        },
        helpfultips: {
            icon: "tips.png",
            label: "Helpful Tips",
            link: "sections/helpful-tips/index.html",
            id: "sectionHelpfulTipsIframe",
            labelLangs: {
                ar: "نصائح مفيدة",
                en: "Helpful Tips",
                zh: "有用的提示",
                de: "Hilfreiche Tipps",
                iw: "טיפים יעילים",
                it: "Consigli utili",
                ro: "Sfaturi de ajutor",
                es: "Consejos útiles",
                tr: "Yararlı İpuçları",
                fr: "Conseils utiles",
                th: "เคล็ดลับที่เป็นประโยชน์"
            }
        },
        consentdocuments: {
            icon: "consent-documents.png",
            label: "Sign Documents",
            link: "sections/consent-documents/index.html",
            id: "sectionConsentDocumentsIframe",
            labelLangs: {
                ar: "Sign Documents",
                en: "Sign Documents",
                zh: "Sign Documents",
                de: "Sign Documents",
                iw: "Sign Documents",
                it: "Sign Documents",
                ro: "Sign Documents",
                es: "Sign Documents",
                tr: "Sign Documents",
                fr: "Sign Documents",
                th: "Sign Documents"
            }
        },
        suggestion: {
            icon: "suggestion.png",
            label: "Suggestion",
            link: "sections/suggestion/index.html",
            id: "sectionSuggestionIframe",
            labelLangs: {
                ar: "اقتراح",
                en: "Suggestion",
                zh: "建议",
                de: "Anregung",
                iw: "הַצָעָה",
                it: "Suggerimento",
                ro: "Sugestie",
                es: "Sugerencia",
                tr: "Öneri",
                fr: "Suggestion",
                th: "คำแนะนำ"
            }
        },
        // guide: {
        //     icon: "guide.png",
        //     label: "User Guide",
        //     link: "sections/user-guide/user-guide.html",
        //     resolution: "fit",
        //     id: "sectionGuideIframe",
        //     labelLangs: {
        //         ar: "دليل المستخدم",
        //         en: "User Guide",
        //         zh: "用户指南",
        //         de: "Benutzerhandbuch",
        //         iw: "מדריך למשתמש",
        //         it: "Guida utente",
        //         ro: "Manualul utilizatorului",
        //         es: "Guía del usuario",
        //         tr: "Kullanici rehberi",
        //         fr: "Mode d'emploi",
        //         th: "คู่มือผู้ใช้"
        //     }
        // },
        logout: {
            icon: "logout.png",
            label: "Logout",
            labelLangs: {
                ar: "تسجيل خروج",
                en: "Logout",
                zh: "登出",
                de: "Ausloggen",
                iw: "להתנתק",
                it: "Disconnettersi",
                ro: "Deconectare",
                es: "Cerrar sesión",
                tr: "Çıkış Yap",
                fr: "Se déconnecter",
                th: "ออกจากระบบ"
            }
        },
    },
    menuForAdmin: {
        // pos: {
        //     icon: "pos.png",
        //     label: "POS",
        //     link: "../../../pos/login.html?redirect=admin",
        //     labelLangs: {
        //         ar: "نقاط البيع",
        //         en: "POS",
        //         zh: "收银机",
        //         de: "Pos",
        //         iw: "קופה",
        //         it: "POS",
        //         ro: "POS",
        //         es: "TPV",
        //         tr: "satış noktası",
        //         fr: "PDV",
        //         th: "เครื่องขายหน้าร้าน"
        //     }
        // },
        notification: {
            icon: "notification.png",
            label: "Notification",
            link: "sections/notification/index.html",
            id: "sectionNotificationIframe",
            labelLangs: {
                ar: "Notification",
                en: "Notification",
                zh: "Notification",
                de: "Notification",
                iw: "Notification",
                it: "Notification",
                ro: "Notification",
                es: "Notification",
                tr: "Notification",
                fr: "Notification",
                th: "Notification"
            }
        },
        dashboard: {
            icon: "dashboard.png",
            label: "Dashboard",
            link: "sections/main-section/reports.html",
            id: "sectionDashboardIframe",
            labelLangs: {
                ar: "لوحة القيادة",
                en: "Dashboard",
                zh: "仪表盘",
                de: "Armaturenbrett",
                iw: "לוּחַ מַחווָנִים",
                it: "Pannello",
                ro: "Bord",
                es: "Tablero",
                tr: "Gösterge Paneli",
                fr: "Tableau de bord",
                th: "แผงควบคุม"
            }
        },
        locations: {
            icon: "locations.png",
            label: "Locations",
            link: "sections/main-section/location-admin.html",
            id: "sectionLocationsIframe",
            labelLangs: {
                ar: "المواقع",
                en: "Locations",
                zh: "地点",
                de: "Standorte",
                iw: "מיקומים",
                it: "Luoghi",
                ro: "Locații",
                es: "Ubicaciones",
                tr: "konumlar",
                fr: "Emplacements",
                th: "สถานที่"
            }
        },
        customers: {
            icon: "customers.png",
            label: "Customers",
            link: "sections/customers-marketing/index.html",
            id: "sectionCustomerIframe",
            labelLangs: {
                ar: "عملاء",
                en: "Customers",
                zh: "顾客",
                de: "Kunden",
                iw: "לקוחות",
                it: "Clienti",
                ro: "Clienți",
                es: "Clientes",
                tr: "Müşteriler",
                fr: "Les clients",
                th: "ลูกค้า"
            }
        },
        employees: {
            icon: "employees.png",
            label: "Employees",
            link: "sections/manage-employee/index.html",
            id: "sectionEmployeesIframe",
            labelLangs: {
                ar: "الموظفين",
                en: "Employees",
                zh: "雇员",
                de: "Angestellte",
                iw: "עובדים",
                it: "Dipendenti",
                ro: "Angajatii",
                es: "Empleados",
                tr: "Çalışanlar",
                fr: "Employés",
                th: "พนักงาน"
            }
        },
        inventory: {
            icon: "inventory.png",
            label: "Inventory",
            link: "sections/inventory/index.html",
            id: "sectionInventoryIframe",
            labelLangs: {
                ar: "جرد",
                en: "Inventory",
                zh: "存货",
                de: "Inventar",
                iw: "מְלַאי",
                it: "Inventario",
                ro: "Inventar",
                es: "Inventario",
                tr: "Envanter",
                fr: "Inventaire",
                th: "รายการสิ่งของ"
            }
        },
        accounting: {
            icon: "reports.png",
            label: "Reports",
            link: "sections/administration/index.html",
            id: "sectionAccountingIframe",
            labelLangs: {
                ar: "التقارير",
                en: "Reports",
                zh: "报告",
                de: "Berichte",
                iw: "דיווחים",
                it: "Rapporti",
                ro: "Rapoarte",
                es: "Informes",
                tr: "Raporlar",
                fr: "Rapports",
                th: "รายงาน"
            }
        },
        appointment: {
            icon: "appointments.png",
            label: "Appointments",
            link: "sections/calendar/appointmentsadmin.html",
            id: "sectionAppointmentIframe",
            labelLangs: {
                ar: "تعيينات",
                en: "Appointments",
                zh: "约会",
                de: "Termine",
                iw: "פגישות",
                it: "Appuntamenti",
                ro: "Rezervări",
                es: "Equipo",
                tr: "Randevular",
                fr: "Rendez-vous",
                th: "การนัดหมาย"
            }
        },
        onlinestore: {
            icon: "kicker-logo-short.png",
            label: "Kicker Point",
            link: "sections/kicker-point/index.html?rf=spiframe",
            // link: "https://kickerpoint.web.app/admin/index.html?rf=sp",
            // link: "../kicker-point/admin/index.html?rf=spiframe",
            id: "sectionKickerPointIframe",
            labelLangs: {
                ar: "كيكر بوينت",
                en: "Kicker Point",
                zh: "踢脚点",
                de: "Kickerpunkt",
                iw: "קיקר פוינט",
                it: "Punto Kicker",
                ro: "Kicker Point",
                es: "punto de pateador",
                tr: "Vuruş Noktası",
                fr: "Point de départ",
                th: "คิกเกอร์พอยท์"
            }
        },
        myaccount: {
            icon: "myaccount.png",
            label: "My Account",
            link: "sections/main-section/my-account.html",
            resolution: "fit",
            id: "sectionMyAccountIframe",
            labelLangs: {
                ar: "حسابي",
                en: "My Account",
                zh: "我的账户",
                de: "Mein Konto",
                iw: "החשבון שלי",
                it: "Il mio conto",
                ro: "Contul meu",
                es: "Mi cuenta",
                tr: "Hesabım",
                fr: "Mon compte",
                th: "บัญชีของฉัน"
            }
        },
        feedback: {
            icon: "feedback.png",
            label: "Feedback",
            link: "sections/feedback/index.html",
            id: "sectionFeedbackIframe",
            labelLangs: {
                ar: "ردود الفعل",
                en: "Feedback",
                zh: "回馈",
                de: "Rückmeldung",
                iw: "מָשׁוֹב",
                it: "Feedback",
                ro: "Părere",
                es: "Realimentación",
                tr: "Geri bildirim",
                fr: "Retour",
                th: "ข้อเสนอแนะ"
            }
        },
        helpfultips: {
            icon: "tips.png",
            label: "Helpful Tips",
            link: "sections/helpful-tips/index.html",
            id: "sectionHelpfulTipsIframe",
            labelLangs: {
                ar: "نصائح مفيدة",
                en: "Helpful Tips",
                zh: "有用的提示",
                de: "Hilfreiche Tipps",
                iw: "טיפים יעילים",
                it: "Consigli utili",
                ro: "Sfaturi de ajutor",
                es: "Consejos útiles",
                tr: "Yararlı İpuçları",
                fr: "Conseils utiles",
                th: "เคล็ดลับที่เป็นประโยชน์"
            }
        },
        consentdocuments: {
            icon: "consent-documents.png",
            label: "Sign Documents",
            link: "sections/consent-documents/index.html",
            id: "sectionConsentDocumentsIframe",
            labelLangs: {
                ar: "Sign Documents",
                en: "Sign Documents",
                zh: "Sign Documents",
                de: "Sign Documents",
                iw: "Sign Documents",
                it: "Sign Documents",
                ro: "Sign Documents",
                es: "Sign Documents",
                tr: "Sign Documents",
                fr: "Sign Documents",
                th: "Sign Documents"
            }
        },
        suggestion: {
            icon: "suggestion.png",
            label: "Suggestion",
            link: "sections/suggestion/index.html",
            id: "sectionSuggestionIframe",
            labelLangs: {
                ar: "اقتراح",
                en: "Suggestion",
                zh: "建议",
                de: "Anregung",
                iw: "הַצָעָה",
                it: "Suggerimento",
                ro: "Sugestie",
                es: "Sugerencia",
                tr: "Öneri",
                fr: "Suggestion",
                th: "คำแนะนำ"
            }
        },
        // guide: {
        //     icon: "guide.png",
        //     label: "User Guide",
        //     link: "sections/user-guide/user-guide.html",
        //     resolution: "fit",
        //     id: "sectionGuideIframe",
        //     labelLangs: {
        //         ar: "دليل المستخدم",
        //         en: "User Guide",
        //         zh: "用户指南",
        //         de: "Benutzerhandbuch",
        //         iw: "מדריך למשתמש",
        //         it: "Guida utente",
        //         ro: "Manualul utilizatorului",
        //         es: "Guía del usuario",
        //         tr: "Kullanici rehberi",
        //         fr: "Mode d'emploi",
        //         th: "คู่มือผู้ใช้"
        //     }
        // },
        logout: {
            icon: "logout.png",
            label: "Logout",
            labelLangs: {
                ar: "تسجيل خروج",
                en: "Logout",
                zh: "登出",
                de: "Ausloggen",
                iw: "להתנתק",
                it: "Disconnettersi",
                ro: "Deconectare",
                es: "Cerrar sesión",
                tr: "Çıkış Yap",
                fr: "Se déconnecter",
                th: "ออกจากระบบ"
            }
        },
    },
};
