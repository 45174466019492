import {
    LOCATION_KEY, LOCATION_NAME_KEY, TOKEN_KEY, LOGIN_TYPE_KEY, CLIENT_UID_KEY,
    POS_BLOCK_KEY, ADMIN_BLOCK_KEY, OPENDAY_KEY, INVENTORY_TOKEN_KEY, CART_INFO_KEY,
    USER_ROLE, SUBSCRIBER_ADMIN, CUSTOMER2EDIT, SELECTED_LOCATION_KEY
} from "./constants.js";
import {formatDate} from "./utils.js";

export const login = (uuid, role, type, subscriberAdminLoggedIn = 'false') => {
    if (role) localStorage.setItem(`${TOKEN_KEY}_${role}`, uuid); else localStorage.setItem(TOKEN_KEY, uuid);

    localStorage.setItem(LOGIN_TYPE_KEY, type);

    if (role === USER_ROLE.EMPLOYEE.id) {
        setPosUnBlock();
    } else if (role === USER_ROLE.ADMIN.id) {
        setAdminUnBlock();
    }
    setSubscriberAdmin(subscriberAdminLoggedIn);
    localStorage.setItem('main-section', 'false');
    localStorage.setItem('APP_LOGIN', 'false');
};

export const logout = (role, section = '') => {
    if (role) localStorage.removeItem(`${TOKEN_KEY}_${role}`); else localStorage.removeItem(TOKEN_KEY);
    if (section === 'admin') {
        localStorage.clear();
    }
    if (role === USER_ROLE.EMPLOYEE.id) {
        setPosUnBlock();
    } else if (role === USER_ROLE.ADMIN.id) {
        setAdminUnBlock();
    }
};

export const getUuid = (role) => {
    if (role) return localStorage.getItem(`${TOKEN_KEY}_${role}`);
    return localStorage.getItem(TOKEN_KEY);
};

export const setLoggedinType = (role) => {
    return localStorage.setItem(LOGIN_TYPE_KEY, role);
};

export const getLoggedinType = () => {
    return localStorage.getItem(LOGIN_TYPE_KEY);
};

export const isLogin = (role) => {
    if (role) {
        if (localStorage.getItem(`${TOKEN_KEY}_${role}`)) return true;
        return false;
    }

    if (localStorage.getItem(TOKEN_KEY)) return true;
    return false;
};

export const setLocationId = (locationId) => {
    localStorage.setItem(LOCATION_KEY, locationId);
};

export const getLocationId = () => {
    return localStorage.getItem(LOCATION_KEY);
};

export const setLocationName = (locationName) => {
    localStorage.setItem(LOCATION_NAME_KEY, locationName);
};

export const getLocationName = () => {
    return localStorage.getItem(LOCATION_NAME_KEY);
};

export const setStoreType = (type = 'location') => {
    localStorage.setItem('STORE_TYPE', type);
};

export const getStoreType = () => {
    return localStorage.getItem('STORE_TYPE') || null;
};

export const setWebSectionLoginInfo = (key = 'ADMIN_SECTION_WEB') => {
    return localStorage.setItem(key, 'true');
};

export const getWebSectionLoginInfo = (key = 'ADMIN_SECTION_WEB') => {
    return localStorage.getItem(key) ?? "false";
};

export const setClientUid = (clientId, section = '') => {
    localStorage.setItem(CLIENT_UID_KEY, clientId);
    localStorage.setItem(CLIENT_UID_KEY, clientId);
    if (section !== '') {
        localStorage.setItem(section + CLIENT_UID_KEY, clientId);
    }
};

export const getClientUid = (section = '') => {
    if (section !== '') {
        if (localStorage.getItem(CLIENT_UID_KEY) && localStorage.getItem(section + CLIENT_UID_KEY)) {
            let item = localStorage.getItem(CLIENT_UID_KEY);
            return (item && item !== 'false') ? item : '';
        } else {
            return '';
        }
    } else {
        let item = localStorage.getItem(CLIENT_UID_KEY);
        return (item && item !== 'false') ? item : '';
    }
};

export const removeClientUid = () => {
    localStorage.removeItem(CLIENT_UID_KEY);
};

export const setPosBlock = () => {
    localStorage.setItem(POS_BLOCK_KEY, "true");
};

export const setPosUnBlock = () => {
    localStorage.setItem(POS_BLOCK_KEY, "false");
};

export const getPosBlock = () => {
    if (localStorage.getItem(POS_BLOCK_KEY) == "true") return true; else return false;
};

export const setAdminBlock = () => {
    localStorage.setItem(ADMIN_BLOCK_KEY, "true");
};

export const setAdminUnBlock = () => {
    localStorage.setItem(ADMIN_BLOCK_KEY, "false");
};

export const getAdminBlock = () => {
    if (localStorage.getItem(ADMIN_BLOCK_KEY) == "true") return true; else return false;
};

export const setSubscriberAdmin = (itemValue = 'false') => {
    localStorage.setItem(SUBSCRIBER_ADMIN, itemValue);
};

export const getSubscriberAdmin = () => {
    return localStorage.getItem(SUBSCRIBER_ADMIN) === "true";
};

export const setDoneOpenday = () => {
    var date = formatDate();
    localStorage.setItem(OPENDAY_KEY, date);
};

export const getDoneOpenday = () => {
    var date = formatDate();
    return localStorage.getItem(OPENDAY_KEY) == date;
};

export const setInventoryLogin = (uid) => {
    localStorage.setItem(INVENTORY_TOKEN_KEY, uid);
};

export const setInventoryLogout = () => {
    localStorage.removeItem(INVENTORY_TOKEN_KEY);
};

export const getInventoryLogin = () => {
    if (localStorage.getItem(INVENTORY_TOKEN_KEY)) return true; else return false;
};

export const saveCartInfo = (cart) => {
    let json = JSON.stringify(cart);
    localStorage.setItem(CART_INFO_KEY, json);
};

export const getCartInfo = () => {
    let obj = null;
    let json = localStorage.getItem(CART_INFO_KEY);
    if (json && json !== "undefined" && json !== null) {
        obj = JSON.parse(json);
    }
    return JSON.parse(JSON.stringify(obj));
};

export const setEditCustomer = (customerid) => {
    localStorage.setItem(CUSTOMER2EDIT, customerid);
};

export const getEditCustomer = () => {
    return localStorage.getItem(CUSTOMER2EDIT);
};

export const setLocalStorageKey = (key, value) => {
    localStorage.setItem(key, value);
};

export const getLocalStorageKey = (key, jsonParse = false) => {
    if(jsonParse) {
        return JSON.parse(localStorage.getItem(key));
    }
    return localStorage.getItem(key);
};

export const removeLocalStorageKey = (key) => {
    localStorage.removeItem(key);
};

export const getSelectedLocations = () => {
    var json = localStorage.getItem(SELECTED_LOCATION_KEY);
    var location = JSON.parse(json);
    if(!location) {
        location = {};
    }
    return location;
};

export const setSelectedLocations = (locations) => {
    var json = JSON.stringify(locations);
    return localStorage.setItem(SELECTED_LOCATION_KEY, json);
};
