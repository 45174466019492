/**
 * Multi Framework build configs
 */
export const SP_APP_PROD_BUILD_VERSION = '28.10';

export const SP_APP_TEST_BUILD_VERSION = '28.10';

export const SP_KICKERPOINT_APP_BUILD_VERSION = '28.10';

export const SP_CLIENT_APP_BUILD_VERSION = '28.10';

export const SP_EMPLOYEE_APP_BUILD_VERSION = '28.10';

export const SP_OWNER_MANAGER_APP_BUILD_VERSION = '28.10';

export const SP_CRM_APP_VERSION = 'v.28.10.24';

export const SP_SUBSCRIBER_CONTROL_APP_VERSION = 'v.28.10.24';

export const SP_APP_BUILD_ENV  = 'salepro-admin';  // "salepro-all" or "salepro-admin" or "salepro-pos" or "salepro-client" or "salepro-employee" or "salepro-subscriber" or "salepro-kicker" or "store-admin-manager";

export const SP_APP_BUILD_FRAMEWORK  = 'web'; // "web" or "electron-mac" or "electron-windows" or "cordova-ios" or "cordova-android";

export const SP_INSTALL_SERVICE_WORKER = true; // WEB AND ELECTRON build set value true.

export const SP_SHOW_UPDATES_INFORMATION_LINK = true; // WEB build set value true.

export const SP_ADMIN_APP_SHOW_NOTIFICATION_MENU = true; // WEB AND ELECTRON AND CORDOVA build set value true if salepro-admin build.

export const SP_APPEND_CORDOVA_SCRIPTS = false; // CORDOVA build set value true.

