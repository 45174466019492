import config from "./admin/config.js";

import * as AppUtilsFunc from "./common/utils/utils.js";
import * as AppLsFunc from "./common/utils/localStorage.js";
import * as AppConstants from "./common/utils/constants.js";

import * as AmplifyAuthController from "./common/aws-amplify/amplify-auth.js";
import * as AmplifyDbController from "./common/aws-amplify/amplify-datastore.js";

import * as UserController from "./common/db/user-db.js";
import * as SaleProConfigController from "./common/db/salepro-config-db.js";
import * as ActivityLogController from "./common/db/activity-logs-db.js";
import * as FCMController from "./common/firebase/firebase-fcm.js";

/**
 * Represents the URL for the application dashboard.
 *
 * @type {string}
 */
let appDashboardURL = 'admin/index.html';

/**
 * Variable to track the loaded state.
 *
 * @type {boolean}
 */
let loaded = false;

/**
 * Represents the login attempt result on the second try.
 *
 * @type {boolean}
 */
let login2ndTry = false;

$(document).ready(async function () {

    console.log(`======== DOCUMENT READY STARTED ========`);

    $('.storeNameStyle').hide();
    $('.salepro-version').text('v.' + AppUtilsFunc.getSaleProVersion());

    /**
     * It will clear localStorage data for login information
     * If the code commented then if the user logged in and reopened the app, then it will auto login to dashboard.
     * If the code is uncommented, then the user has to log in all the time when open the app.
     */
    // AppUtilsFunc.clearLocalStorage();

    AppUtilsFunc.showBlockUI();

    let isLoggedIn = await AmplifyAuthController.isLoggedIn(true);
    await initSaleProLogin(isLoggedIn);
});

/**
 * Handles the login form for the admin app.
 *
 * @async
 *
 * @function adminAppLoginFormHandler
 *
 * @returns {Promise<void>} - The function does not return a value.
 */
const adminAppLoginFormHandler = async () => {

    if (AppConstants.SALEPRO_APP_BUILD_FRAMEWORK === 'web') {
        await FCMController.getSwRegistrationInstance();
    }

    AppUtilsFunc.showHidePasswordEvent();

    $('#loginForm').validate({
        rules: {
            loginUserEmail: {
                required: true,
            },
            loginUserPassword: {
                required: true,
            },
        },
        ignore: [], errorPlacement: function errorPlacement(error, element) {
            $(element).parents('div.form-group').find('span.validation-errors').append(error);
        },
        onfocusout: false,
        highlight: function (element, errorClass) {
            $(element).addClass(errorClass);
        },
        unhighlight: function (element, errorClass) {
            $(element).removeClass(errorClass);
        },
        submitHandler: function (form) {
            if ($(form).valid()) {
                AppUtilsFunc.showBlockUI();
                let accountEmail = $('#loginUserEmail').val();
                let accountPassword = $('#loginUserPassword').val();
                proceedGeneralLogin(accountEmail, accountPassword);
            }
        },
    });
};

/**
 * Performs a general login using the provided account email and password.
 * If the login is successful, it checks and proceeds with app selection.
 * If the login fails, it shows an alert message.
 * If a UserNotConfirmedException error occurs, it prompts the user to verify their email address.
 * If any other error occurs, it shows the corresponding AWS exception message.
 *
 * @param {string} accountEmail - The email associated with the account.
 * @param {string} accountPassword - The password for the account.
 * @returns {Promise} A promise that resolves when the login process is complete.
 * @throws {Error} If an error occurs during the login process.
 */
const proceedGeneralLogin = async (accountEmail, accountPassword) => {

    try {
        let loginResponse = await AmplifyAuthController.loginUser(accountEmail, accountPassword, false);

        // console.log('General Login Response: ', loginResponse);
        if (loginResponse !== undefined) {
            const clientAccountUID = loginResponse.username;
            await checkAndProceedAppSelection(clientAccountUID, true);
        } else {
            AppUtilsFunc.showAlertMessage('Invalid operation performed!', 'danger');
        }

    } catch (catchErr) {

        if (catchErr.name === 'UserNotConfirmedException') {
            AppUtilsFunc.hideBlockUI();
            bootbox.prompt({
                title: 'We sent a verification code on your registered email address. Please check your email and verify here with the code.',
                message: '<p class="text-bold text-danger">Check that it has not arrived in the SPAM folder of your email.</p>',
                centerVertical: true,
                closeButton: false,
                required: true,
                callback: async function (confirmationCode) {
                    if (confirmationCode) {
                        AppUtilsFunc.showBlockUI();
                        await AmplifyAuthController.confirmSignUp(accountEmail, confirmationCode).then(async (verified) => {
                            AppUtilsFunc.hideBlockUI();
                            if (verified) {
                                AppUtilsFunc.showAlertMessage("Your account is now verified. You can login now.", "success");
                            } else {
                                AppUtilsFunc.showAlertMessage("Your email has not been verified. Either you entered the wrong verification code or the code time expired. Please try again.", "warning");
                                $("form .msg-error").css("display", "block");
                            }
                        });
                    } else {
                        AppUtilsFunc.showAlertMessage("You cancelled the verification process. Without verification you can not sign in.", "warning");
                        AppUtilsFunc.hideBlockUI();
                        $("form .msg-error").css("display", "block");
                    }
                }
            });
        } else {
            AppUtilsFunc.showAwsExceptionMessage(catchErr);
        }
        login2ndTry = false;
        AppUtilsFunc.hideBlockUI();
    }
};

/**
 * Checks and proceeds with the application selection for the given client account UID.
 * @param {string} clientAccountUID - The client account UID.
 * @param {boolean} [saveLog=false] - Whether to save the activity log or not.
 * @returns {Promise<void>}
 */
const checkAndProceedAppSelection = async (clientAccountUID, saveLog = false) => {

    let clientData = await UserController.getDataUserAdmin(clientAccountUID)
        .catch(async (error) => {
            console.log('getDataUserAdmin catch error: ', error);

            await AmplifyAuthController.logoutUser().catch((err) => console.log(err));

            AppUtilsFunc.hideBlockUI();
            AppUtilsFunc.showAlertMessage('Please provide valid store admin credentials.', 'danger');
            AppUtilsFunc.clearLocalStorage();

            await adminAppLoginFormHandler();
        });
    // console.log('clientData', clientData);

    if (clientData !== undefined) {

        AppLsFunc.setLocalStorageKey('GENERAL_LOGIN', 'true');
        AppLsFunc.setClientUid(clientAccountUID, 'admin');

        let currentLoginTime = moment().format();

        await UserController.loginUserToApp(clientAccountUID, AppConstants.APP_TYPE.admin, AppConstants.USER_ROLE.ADMIN.id, currentLoginTime);

        AppLsFunc.login(clientAccountUID, AppConstants.USER_ROLE.ADMIN.id, AppConstants.USER_ROLE.ADMIN.id);
        AppLsFunc.setClientUid(clientAccountUID, 'admin');

        AppLsFunc.setWebSectionLoginInfo();

        if (AppConstants.SALEPRO_APP_BUILD_FRAMEWORK === 'electron-mac' || AppConstants.SALEPRO_APP_BUILD_FRAMEWORK === 'electron-windows' || AppConstants.SALEPRO_APP_BUILD_FRAMEWORK === 'web') {
            let deviceData = await getDeviceDataByBuildType(clientAccountUID);
            await UserController.setDeviceTokenToAdminUser(clientAccountUID, deviceData, clientData, getDeviceAppTypeByBuildType());
        }

        if (saveLog) {
            await ActivityLogController.addLog({
                date: currentLoginTime,
                timezone: moment.tz.guess(),
                clientUid: AppLsFunc.getClientUid('admin'),
                uid: clientAccountUID,
                operation: 'adminLogin',
                currentPage: 'adminLogin',
                nextPage: 'adminDashboard',
                subscriberControlOperation: AppLsFunc.getLocalStorageKey('subscriberAdmin') === 'true',
                storeId: '',
                storeType: '',
                message: '',
                ip: {},
            });
        }
        AppUtilsFunc.changePageByName(appDashboardURL);

    } else {

        let storePermission = await UserController.getDataStoreDirector(clientAccountUID);
        const empUserData = await UserController.getDataUser(clientAccountUID);
        // console.log('storePermission', storePermission);
        // console.log('empUserData', empUserData);

        if ((storePermission !== undefined && storePermission?.adminAccess === true && storePermission?.adminUID) || (empUserData !== undefined && storePermission?.adminUID)) {

            let currentLoginTime = moment().format();

            AppLsFunc.login(clientAccountUID, AppConstants.USER_ROLE.ADMIN.id, AppConstants.USER_ROLE.ADMIN.id);
            AppLsFunc.setLocalStorageKey('GENERAL_LOGIN', 'true');
            AppLsFunc.setLocalStorageKey('ADMIN_APP_LOGIN', 'true');
            AppLsFunc.setClientUid(storePermission?.adminUID, 'admin');
            AppLsFunc.setWebSectionLoginInfo();

            if (AppConstants.SALEPRO_APP_BUILD_FRAMEWORK === 'electron-mac' || AppConstants.SALEPRO_APP_BUILD_FRAMEWORK === 'electron-windows' || AppConstants.SALEPRO_APP_BUILD_FRAMEWORK === 'web') {

                let deviceData = await getDeviceDataByBuildType(clientAccountUID);

                await UserController.setDeviceTokenToEmployeeUser(clientAccountUID, deviceData, empUserData, getDeviceAppTypeByBuildType())
                    .catch(async (error) => {
                        console.log('getDataStoreDirector catch error', error);

                        await AmplifyAuthController.logoutUser().catch((err) => console.log(err));

                        AppUtilsFunc.hideBlockUI();
                        AppUtilsFunc.showAlertMessage('Please provide valid store admin credentials.', 'danger');
                        AppUtilsFunc.clearLocalStorage();

                        await adminAppLoginFormHandler();
                    });
            }

            if (saveLog) {
                await ActivityLogController.addLog({
                    date: currentLoginTime,
                    timezone: moment.tz.guess(),
                    clientUid: AppLsFunc.getClientUid('admin'),
                    uid: clientAccountUID,
                    operation: 'adminLogin',
                    currentPage: 'adminLogin',
                    nextPage: 'adminDashboard',
                    subscriberControlOperation: AppLsFunc.getLocalStorageKey('subscriberAdmin') === 'true',
                    storeId: '',
                    storeType: '',
                    message: '',
                    ip: {},
                });
            }

            AppUtilsFunc.changePageByName(appDashboardURL);

        } else {

            await AmplifyAuthController.logoutUser().catch((err) => console.log(err));
            AppUtilsFunc.hideBlockUI();
            AppUtilsFunc.showAlertMessage('You do not have permission contact your administrator.', 'danger');
            AppUtilsFunc.clearLocalStorage();
            await adminAppLoginFormHandler();
        }
    }
};

/**
 * Retrieves device data based on the build type.
 * @param {string} clientAccountUID - The client account UID.
 * @returns {Promise<Object>} - The device data tokens.
 */
const getDeviceDataByBuildType = async (clientAccountUID) => {

    let tokens;
    try {
        switch (AppConstants.SALEPRO_APP_BUILD_FRAMEWORK) {
            case "electron-windows":
                // TODO:: Implement Windows Token Generation in electron admin app first
                // if (window?.electronAPI) {
                //     tokens = await window?.electronAPI?.generateWindowsToken().then(res => {
                //         if (res.status === true && res.data.deviceUuid && res.data.oneSignalSubscriptionId) {
                //             return {[res.data.deviceUuid]: res.data.oneSignalSubscriptionId}
                //         } else {
                //             return {};
                //         }
                //     });
                // } else {
                //     tokens = {};
                // }
                tokens = {};
                break;
            case "electron-mac":
                if (window?.electronAPI) {
                    tokens = await window?.electronAPI?.generateMacApnsToken().then(res => {
                        if (res.status === true && res.data.deviceUuid && res.data.oneSignalSubscriptionId) {
                            return {[res.data.deviceUuid]: res.data.oneSignalSubscriptionId};
                        } else {
                            return {};
                        }
                    });
                } else {
                    tokens = {};
                }
                break;
            default:
                tokens = await FCMController.generateDeviceTokenForFCM();
                break;
        }
        return tokens;
    } catch (e) {
        console.log('getDeviceDataByBuildType catch error: ', e);
        tokens = {};
        return tokens;
    }
};

/**
 * Retrieves the device application type based on the build type.
 * @returns {string} - The device application type ('electron' or 'web').
 */
const getDeviceAppTypeByBuildType = () => {
    let tokens;
    switch (AppConstants.SALEPRO_APP_BUILD_FRAMEWORK) {
        case "electron-windows":
        case "electron-mac":
            tokens = 'electron';
            break;
        default:
            tokens = 'web';
            break;
    }
    return tokens;
};

/**
 * Initializes the SalePro login process.
 * @async
 * @param {any} loginResponse - The response object from the login process.
 */
const initSaleProLogin = async (loginResponse) => {

    let menu = config.menuForAdmin;

    if (AppConstants.SALEPRO_APP_BUILD_FRAMEWORK === 'web') {
        appDashboardURL = '/admin/' + menu.dashboard.link;
    } else {
        appDashboardURL = 'admin/' + menu.dashboard.link;
    }

    // console.log(loaded, loginResponse);
    if (!loginResponse) {
        // console.log('test');
        AppUtilsFunc.clearLocalStorage();
        await AmplifyDbController.syncDataByClientAccount('', 'all', true).then(r => console.log('r')).catch(e => console.error('e'));
        AppUtilsFunc.hideBlockUI();
        await adminAppLoginFormHandler();
        return;
    }

    if (AppLsFunc.getClientUid('admin')) {

        let adminData = await UserController.getDataUserAdmin(AppLsFunc.getClientUid('admin'))
            .catch(async (error) => {
                await (userResetNoLoggedIn());
            });

        if (adminData !== undefined) {

            let saleProConfigs = await SaleProConfigController.getSaleProConfigs(true);
            let configVersion = AppConstants.SALEPRO_ENVIRONMENT === 'TEST' ? saleProConfigs?.saleproTestVersion : saleProConfigs?.saleproVersion;
            let adminDataVersion = AppConstants.SALEPRO_ENVIRONMENT === 'TEST' ? adminData?.saleProTestVersion : adminData?.saleProVersion;
            let updateKey = AppConstants.SALEPRO_ENVIRONMENT === 'TEST' ? 'saleProTestVersion' : 'saleProVersion';

            if (configVersion === undefined || configVersion === 0 || (Number(adminDataVersion) < Number(configVersion) || Number(adminDataVersion) > Number(configVersion))) {

                await UserController.setDataUserAdmin(AppLsFunc.getClientUid('admin'), {[updateKey]: Number(configVersion)})
                    .then(res => console.log(res))
                    .catch(err => console.log(err));

            } else if (adminDataVersion === undefined || (Number(adminDataVersion) < Number(AppUtilsFunc.getSaleProVersion()) || Number(adminDataVersion) > Number(AppUtilsFunc.getSaleProVersion()))) {

                await UserController.setDataUserAdmin(AppLsFunc.getClientUid('admin'), {[updateKey]: Number(AppUtilsFunc.getSaleProVersion())})
                    .then(res => console.log(res))
                    .catch(err => console.log(err));

            } else if (Number(configVersion) === Number(AppUtilsFunc.getSaleProVersion()) && Number(adminDataVersion) !== Number(AppUtilsFunc.getSaleProVersion())) {

                await UserController.setDataUserAdmin(AppLsFunc.getClientUid('admin'), {[updateKey]: Number(configVersion)})
                    .then(res => console.log(res))
                    .catch(err => console.log(err));
            }

            AppLsFunc.setWebSectionLoginInfo();
            await checkAndProceedAppSelection(AppLsFunc.getClientUid('admin'), false);

        } else {
            await (userResetNoLoggedIn());
        }
    } else {
        await (userResetNoLoggedIn());
    }
};

const userResetNoLoggedIn = async () => {
    // console.log('test');
    await AmplifyAuthController.logoutUser().catch((err) => console.log(err));
    AppLsFunc.logout(AppConstants.USER_ROLE.ADMIN.id);
    AppUtilsFunc.clearLocalStorage();
    await AmplifyDbController.syncDataByClientAccount('', 'all', true).then(r => console.log('r')).catch(e => console.error('e'));
    AppUtilsFunc.hideBlockUI();
    await adminAppLoginFormHandler();
};
