import {USER_ROLE, APP_TYPE, RES_STATUS, AWS_DATASTORE_SCHEMA_NAMES} from "../utils/constants.js";
import {getClientUid} from "../utils/localStorage.js";
import {customAsyncForEach, isActiveInternetConnection, isDefined, showToastMessage, showAwsExceptionToastMessage} from "../utils/utils.js";

import * as AmplifyDbController from "../aws-amplify/amplify-datastore.js";
import * as AmplifyAuthController from "../aws-amplify/amplify-auth.js";
import * as AmplifyStorageController from "../aws-amplify/amplify-storage.js";
import * as AWS_AdminSDK from "../aws-amplify/aws-admin-functions.js";

export const loginUser = async (username, password) => {
    return await AmplifyAuthController.loginUser(username, password, false);
};

export const loginUserToApp = async (uuid, app, role, loginTime = null) => {
    let data = {};
    if (loginTime === null) {
        loginTime = moment().format();
    }
    if (app === APP_TYPE.admin) {
        data = {
            adminAppLastLogin: loginTime,
        };
    } else if (app === APP_TYPE.pos) {
        data = {
            posAppLastLogin: loginTime,
        };
    }
    // if (role === USER_ROLE.ADMIN.id) {
    //     return AmplifyDbController.updateDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProClients, uuid, data);
    // } else {
    //     return AmplifyDbController.updateDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers, uuid, data);
    // }
    return await AmplifyDbController.updateDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProClients, uuid, data);
};

export const isLoggedIn = (callback, returnUserCred = false) => {
    AmplifyAuthController.isLoggedIn(returnUserCred).then(callback);
};

export const setDeviceTokenToAdminUser = async (uuid, deviceData = {}, uidData = {}, appType = 'web') => {
    if ($.isEmptyObject(deviceData) === false) {
        let updateKey = appType === 'electron' ? 'oneSignalDeviceTokens' : 'deviceTokens';
        let uidDocumentData = uidData?.clientUid ? JSON.parse(JSON.stringify(uidData)) : await getDataUserAdmin(uuid).then(data => JSON.parse(JSON.stringify(data ?? {})));
        let postData = {[updateKey]: deviceData};
        let deviceIDs = Object.keys(deviceData);
        if (typeof uidDocumentData[updateKey] !== "undefined") {
            if (uidDocumentData[updateKey] === null) {
                uidDocumentData[updateKey] = {};
            }
            uidDocumentData[updateKey][deviceIDs[0]] = deviceData[deviceIDs[0]];
            postData[updateKey] = uidDocumentData[updateKey];
        }
        localStorage.setItem('logged-in-token', deviceIDs[0]);
        await AmplifyDbController.updateDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProClients, uuid, postData);
    }
};

export const setDeviceTokenToClientUser = async (uuid, deviceData = {}, appType = 'web') => {
    if ($.isEmptyObject(deviceData) === false) {
        let updateKey = appType === 'electron' ? 'oneSignalDeviceTokens' : 'deviceTokens';
        let uidDocumentData = await getDataCustomer(uuid).then(data => JSON.parse(JSON.stringify(data ?? {})));
        let postData = {[updateKey]: deviceData};
        let deviceIDs = Object.keys(deviceData);
        if (typeof uidDocumentData[updateKey] !== "undefined") {
            if (uidDocumentData[updateKey] === null) {
                uidDocumentData[updateKey] = {};
            }
            uidDocumentData[updateKey][deviceIDs[0]] = deviceData[deviceIDs[0]];
            postData[updateKey] = uidDocumentData[updateKey];
        }
        localStorage.setItem('logged-in-token', deviceIDs[0]);
        await AmplifyDbController.updateDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProAppCustomers, uuid, postData);
    }
};

export const setDeviceTokenToEmployeeUser = async (uuid, deviceData = {}, uidData = {}, appType = 'web') => {
    if ($.isEmptyObject(deviceData) === false) {
        let updateKey = appType === 'electron' ? 'oneSignalDeviceTokens' : 'deviceTokens';
        let uidDocumentData = uidData?.uid ? JSON.parse(JSON.stringify(uidData)) : await getDataUser(uuid).then(data => JSON.parse(JSON.stringify(data ?? {})));
        let postData = {[updateKey]: deviceData};
        let deviceIDs = Object.keys(deviceData);
        if (typeof uidDocumentData[updateKey] !== "undefined") {
            if (uidDocumentData[updateKey] === null) {
                uidDocumentData[updateKey] = {};
            }
            uidDocumentData[updateKey][deviceIDs[0]] = deviceData[deviceIDs[0]];
            postData[updateKey] = uidDocumentData[updateKey];
        }
        localStorage.setItem('logged-in-token', deviceIDs[0]);
        await AmplifyDbController.updateDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers, uuid, postData);
    }
};

export const setDataUserAdmin = (uuid, postData) => {
    return AmplifyDbController.updateDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProClients, uuid, postData);
};

export const addStoreSubscriptions = (postData) => {
    return AmplifyDbController.insertDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSubscriptions, postData);
};

export const getStoreSubscriptions = (clientUid, billingCycle = null) => {
    if (billingCycle) {
        return AmplifyDbController.getDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSubscriptions, [
            {fieldName: 'clientUid', conditionType: 'eq', fieldValue: clientUid},
            {fieldName: 'billingCycle', conditionType: 'eq', fieldValue: billingCycle},
        ], 'paymentDate', 'desc').then((data) => {
            return (data.length > 0) ? data[0] : {};
        }).catch(() => {
            return {};
        });
    } else {
        return AmplifyDbController.getDataFromAwsDataStoreByCondition(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSubscriptions, 'eq', 'clientUid', clientUid, 'paymentDate', 'desc').then((querySnapshot) => {
            let list = {};
            querySnapshot.forEach((doc) => {
                list[doc.id] = doc;
            });
            return list;
        }).catch(() => {
            return {};
        });
    }
};

export const getDataUserAdmin = (uuid) => {
    return AmplifyDbController.getDataFromAwsDataStoreById(AWS_DATASTORE_SCHEMA_NAMES.SaleProClients, uuid);
};

export const getDataUserAdminKicker = (uuid) => {
    return AmplifyDbController.getDataFromAwsDataStoreById(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsKicker, uuid);
};

export const listenDataUserAdmin = (uuid, render) => {
    AmplifyDbController.listenDataFromCollectionByCondition(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers, render, [
        {fieldName: 'clientUid', conditionType: 'eq', fieldValue: uuid},
        {fieldName: 'role', conditionType: 'eq', fieldValue: USER_ROLE.EMPLOYEE.id},
    ], 'name', 'asc', 'uid');
};

export const getDataUser = async (uuid) => {
    return await AmplifyDbController.getDataFromAwsDataStoreById(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers, uuid);
};

export const getDataStoreDirector = (uuid) => {
    return AmplifyDbController.getDataFromAwsDataStoreById(AWS_DATASTORE_SCHEMA_NAMES.SaleProUsers, uuid);
};

export const getDataCustomer = (uuid) => {
    return AmplifyDbController.getDataFromAwsDataStoreById(AWS_DATASTORE_SCHEMA_NAMES.SaleProAppCustomers, uuid);
};

export const getEmployees = async (orderByField = 'name') => {
    return await AmplifyDbController.getDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers, [
        {fieldName: 'clientUid', conditionType: 'eq', fieldValue: getClientUid()},
        {fieldName: 'role', conditionType: 'eq', fieldValue: USER_ROLE.EMPLOYEE.id},
    ], orderByField, 'asc').then((querySnapshot) => {
        let list = {};
        querySnapshot.forEach((doc) => {
            list[doc.uid] = doc;
        });
        return list;
    }).catch((err) => {
        console.log(err);
        return {};
    });
};

export const getEmployeesByLocations = async (locationId, orderByField = 'name') => {
    return await AmplifyDbController.getDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers, [
        {fieldName: 'clientUid', conditionType: 'eq', fieldValue: getClientUid()},
        {fieldName: 'role', conditionType: 'eq', fieldValue: USER_ROLE.EMPLOYEE.id},
        {fieldName: 'location', conditionType: 'contains', fieldValue: locationId},
    ], orderByField, 'asc').then((querySnapshot) => {
        let list = {};
        querySnapshot.forEach((doc) => {
            list[doc.uid] = doc;
        });
        return list;
    }).catch((err) => {
        console.log(err);
        return {};
    });
};

export const listenEmployees = (render, orderByField = 'name') => {
    AmplifyDbController.listenDataFromCollectionByCondition(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers, render, [
        {fieldName: 'clientUid', conditionType: 'eq', fieldValue: getClientUid()},
        {fieldName: 'role', conditionType: 'eq', fieldValue: USER_ROLE.EMPLOYEE.id},
    ], orderByField, 'asc', 'uid');
};

export const listenEmployeesByLocations = (locationId, render, orderByField = 'name') => {
    AmplifyDbController.listenDataFromCollectionByCondition(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers, render, [
        {fieldName: 'clientUid', conditionType: 'eq', fieldValue: getClientUid()},
        {fieldName: 'role', conditionType: 'eq', fieldValue: USER_ROLE.EMPLOYEE.id},
        {fieldName: 'location', conditionType: 'contains', fieldValue: locationId},
    ], orderByField, 'asc', 'uid');
};


export const listenEmployeesByStorages = (storageId, render, orderByField = 'name') => {
    AmplifyDbController.listenDataFromCollectionByCondition(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers, render, [
        {fieldName: 'clientUid', conditionType: 'eq', fieldValue: getClientUid()},
        {fieldName: 'role', conditionType: 'eq', fieldValue: USER_ROLE.EMPLOYEE.id},
        {fieldName: 'storage', conditionType: 'contains', fieldValue: storageId},
    ], orderByField, 'asc', 'uid');
};

export const getPermission = async (empTypeId) => {
    return await AmplifyDbController.getDataFromAwsDataStoreById(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsEmployeeType, empTypeId);
};

export const listenPermission = (empTypeId, render) => {
    if (empTypeId) {
        AmplifyDbController.listenDataFromCollectionById(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsEmployeeType, empTypeId, render);
    } else {
        render({});
    }
};

export const listenPermissions = (render) => {
    AmplifyDbController.listenDataFromCollectionByCondition(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsEmployeeType, render, [
        {fieldName: 'clientUid', conditionType: 'eq', fieldValue: getClientUid()},
    ]);
};

export const getClients = () => {
    return AmplifyDbController.getDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProAppCustomers, [
        {fieldName: 'clientUIDs', conditionType: 'contains', fieldValue: getClientUid()},
    ], 'name', 'asc').then(async (querySnapshot) => {
        let modifiedData = {};
        await customAsyncForEach(querySnapshot, function (snapshotData) {
            modifiedData[snapshotData.uid] = snapshotData;
        });
        return modifiedData;
    }).catch(() => {
        return {};
    });
};

export const listenAllUsers = (render) => {
    AmplifyDbController.listenAllDataFromCollection(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers, async (querySnapshot) => {
        let modifiedData = {};
        await customAsyncForEach(Object.keys(querySnapshot), function (uid) {
            if (querySnapshot[uid]?.role === USER_ROLE.CLIENT.id && querySnapshot[uid]?.clientUIDs?.includes(getClientUid())) {
                modifiedData[uid] = querySnapshot[uid];
            } else if (querySnapshot[uid]?.role === USER_ROLE.EMPLOYEE.id && querySnapshot[uid]?.clientUid === getClientUid()) {
                modifiedData[uid] = querySnapshot[uid];
            }
        });
        render(modifiedData);
    }, 'name', 'asc', 'uid');
};

export const listenClients = (render) => {
    AmplifyDbController.listenDataFromCollectionByCondition(AWS_DATASTORE_SCHEMA_NAMES.SaleProAppCustomers, render, [
        {fieldName: 'clientUIDs', conditionType: 'contains', fieldValue: getClientUid()},
    ], 'name', 'asc', 'uid');
};

export const addUser = (user) => {
    return AmplifyAuthController.signUpUser(user.email, user.phone, false, true).then((response) => {
        if (isDefined(response, 'code')) {
            return false;
        } else {
            user['uid'] = response;
            return AmplifyDbController.insertDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers, user);
        }
    });
};

export const updateUser = (user, id) => {
    return AmplifyDbController.updateDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers, id, user);
};

export const saveUser = (id, user) => {
    return AmplifyDbController.updateDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers, id, user);
};

export const updateUserWithCredentials = async (user, updateEmail = false, updatePassword = false) => {
    var result = {};

    if (isActiveInternetConnection()) {

        if (updateEmail && user?.oldEmail) {

            let emailExist = await AWS_AdminSDK.getUserData(user.email);

            if (emailExist === false) {

                let statusEmailUpdate = await AWS_AdminSDK.AWS_updateAuthEmail(user?.oldEmail, user.email);

                if (!statusEmailUpdate) {

                    result['status'] = RES_STATUS.FAILED;

                    return result;
                }

            } else {

                result['status'] = RES_STATUS.EMAIL_EXIST;

                return result;
            }
        }

        if (updatePassword) {

            let statusPasswordUpdate = await AWS_AdminSDK.AWS_updateAuthPassword(user.email, user.password);

            if (statusPasswordUpdate === false) {
                result['status'] = RES_STATUS.FAILED;
                return result;
            }
        }

    } else {

        if (updateEmail || updatePassword) {
            delete user.email;
            delete user.password;
            showToastMessage("Notice", "No internet connection. You cannot update employee account email or password.", "error");
        }
    }

    if (user?.oldEmail) {
        delete user.oldEmail;
    }

    if (user?.oldPassword) {
        delete user.oldPassword;
    }

    // if (updateEmail || updatePassword) {
    //     delete user.email;
    //     delete user.password;
    //     showToastMessage("Notice", "Currently, email or password changes are unavailable as we are undergoing system maintenance. We apologize for the inconvenience and assure you that we're working diligently to resolve the issue. Store owners will be notified once the process is complete. Thank you for your understanding and patience.", "warning");
    // }

    return await AmplifyDbController.updateDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers, user.uid, user).then(status => {
        if (status) {
            result['status'] = RES_STATUS.SUCCESS;
        }
        return result;
    });
};

export const loginWithIdOrEmailPassword = (uid, email = null, password = null) => {
    return AmplifyDbController.getDataFromAwsDataStoreById(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers, uid).then((data) => {
        if (data) {
            if (email == null) {
                email = data.email;
            }
            if (password == null) {
                password = data.password;
            }
            return AmplifyAuthController.loginUser(email, password, false);
        }
    });
};

export async function uploadImportDoc(file, uuid, importType = 'product', returnOnlyUrl = false) {
    const fileExt = file.name.split('.').pop();
    const uploadName = `${uuid}-${Date.now()}.${fileExt}`;
    const filePath = `${AWS_DATASTORE_SCHEMA_NAMES.SaleProClients}/${uuid}/import/${importType}`;
    return AmplifyStorageController.uploadFileToAwsStorageBucket(uploadName, file, file.type, filePath).then(async (downloadURL) => {
        if (downloadURL === false) {
            return false;
        } else {
            return returnOnlyUrl ? downloadURL : {
                url: downloadURL,
                dir: `${filePath}/${uploadName}`,
            };
        }
    });
}

export const addClient = (customer) => {
    let result = {};
    return AmplifyAuthController.signUpUser(customer.email, customer.password, false, true).then(async (response) => {
        if (isDefined(response, 'name')) {
            if (response.name === 'UsernameExistsException') {
                result['status'] = "exist";
                result['data'] = await getAppClientUserByEmail(customer.email);
                return result;
            } else {
                showAwsExceptionToastMessage(response);
                return result;
            }
        } else {
            customer['uid'] = response;
            return AmplifyDbController.insertDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProAppCustomers, customer).then(async () => {
                AWS_AdminSDK.AWS_confirmNewAccount(customer?.email);
                result['status'] = "success";
                result['uid'] = response;
                return result;
            });
        }
    });
};

export const updateCustomer = async (id, data) => {
    let dataExist = await getClientData(id);

    if (typeof data.createdAt !== 'undefined') {
        delete data.createdAt;
    }
    if (typeof data.updatedAt !== 'undefined') {
        delete data.updatedAt;
    }

    data['uid'] = id;
    data['clientUid'] = getClientUid();

    if (dataExist !== undefined) {
        if (Object.keys(dataExist).length > 0) {
            return await AmplifyDbController.updateDataInAwsDataStoreByCondition(AWS_DATASTORE_SCHEMA_NAMES.SaleProAppCustomers, 'eq', 'uid', id, data).then(async response => {
                if (response) {
                    await setAppCustomerInfo(id, data);
                }
            });
        }
    }
    return await AmplifyDbController.insertDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProAppCustomers, data).then(async response => {
        if (response) {
            AWS_AdminSDK.AWS_confirmNewAccount(data?.email);
        }
    });
};

export const updateAppCustomer = (id, data) => {
    return AmplifyDbController.updateDataInAwsDataStoreByCondition(AWS_DATASTORE_SCHEMA_NAMES.SaleProAppCustomers, 'eq', 'uid', id, data);
};

export const getAppClientUserByEmail = async (email) => {
    return AmplifyDbController.getDataFromAwsDataStoreByCondition(AWS_DATASTORE_SCHEMA_NAMES.SaleProAppCustomers, 'eq', 'email', email).then((querySnapshot) => {
        return querySnapshot[0] !== undefined ? JSON.parse(JSON.stringify(querySnapshot[0])) : {};
    });
};

export const getClientData = (clientId) => {
    return AmplifyDbController.getDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProAppCustomers, [
        {fieldName: 'role', conditionType: 'eq', fieldValue: USER_ROLE.CLIENT.id},
        {fieldName: 'uid', conditionType: 'eq', fieldValue: clientId}
    ], 'name', 'asc').then((querySnapshot) => {
        let list = {};
        querySnapshot.forEach((doc) => {
            list[doc.uid] = doc;
        });
        return list;
    });
};

export const setAppCustomerInfo = async (customeruid, customerinfo) => {
    let oldCustomerInfo = await getAppCustomerInfo(customeruid);
    if (oldCustomerInfo === undefined) {
        oldCustomerInfo = await getStoreCustomerInfo(customeruid);
    }
    if (oldCustomerInfo !== undefined) {
        let locations = oldCustomerInfo?.location || [];
        if (customerinfo['location'] instanceof Array && locations instanceof Array) {
            customerinfo['location'] = customerinfo['location'].concat(locations).unique();
        } else if (customerinfo['location'] instanceof Array && locations instanceof Array === false) {
            if (!customerinfo['location'].includes(locations)) {
                customerinfo['location'].push(locations);
            }
        } else if (customerinfo['location'] instanceof Array === false && locations instanceof Array) {
            if (!locations.includes(customerinfo['location']) && customerinfo['location']) {
                locations.push(customerinfo['location']);
                customerinfo['location'] = locations;
            }
        } else {
            customerinfo['location'] = [customerinfo['location'], locations];
        }
    } else {
        oldCustomerInfo = {};
    }
    if (oldCustomerInfo['clientUIDs'] === undefined) {
        oldCustomerInfo['clientUIDs'] = [];
    }
    if (!oldCustomerInfo['clientUIDs'].includes(getClientUid())) {
        oldCustomerInfo['clientUIDs'].push(getClientUid());
    }
    customerinfo['clientUIDs'] = oldCustomerInfo['clientUIDs'];
    customerinfo['uid'] = customeruid;
    customerinfo.role = USER_ROLE.CLIENT.id;

    let checkCustomerDataExist = await AmplifyDbController.getDataFromAwsDataStoreByCondition(AWS_DATASTORE_SCHEMA_NAMES.SaleProAppCustomers, 'eq', 'uid', customeruid);

    if (checkCustomerDataExist.length > 0) {
        return await AmplifyDbController.updateDataInAwsDataStoreByCondition(AWS_DATASTORE_SCHEMA_NAMES.SaleProAppCustomers, 'eq', 'uid', customeruid, customerinfo);
    } else {
        return await AmplifyDbController.insertDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProAppCustomers, customerinfo);
    }
};
