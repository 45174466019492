import {initializeApp} from "firebase/app";
import {getMessaging, getToken} from 'firebase/messaging';

import * as AppConstants from "../utils/constants.js";
import * as AppUtils from "../utils/utils.js";

let firebaseAppInstance;
let firebaseAppMessagingInstance;

/**
 * Initialize Firebase App
 * @returns {FirebaseApp}
 */
const getFirebaseAppInstance = () => {
    if (firebaseAppInstance === undefined) {
        firebaseAppInstance = initializeApp(AppConstants.FIREBASE_CONFIG);
    }
    return firebaseAppInstance;
};

/**
 * Initialize Firebase App Messaging
 * @returns {Messaging}
 */
const getFirebaseAppMessagingInstance = () => {
    if (firebaseAppMessagingInstance === undefined) {
        firebaseAppMessagingInstance = getMessaging(getFirebaseAppInstance());
    }
    return firebaseAppMessagingInstance;
};

/**
 * Generate Device Token using Firebase App Messaging
 * @returns {Promise<{}|{}>}
 */
export const generateDeviceTokenForFCM = async () => {
    let returnData = {};
    if (AppConstants.SALEPRO_INSTALL_SERVICE_WORKER === true && AppConstants.SALEPRO_APPEND_CORDOVA_SCRIPTS === false && AppUtils.isActiveInternetConnection() === true) {
        let clientDeviceID = new DeviceUUID().get();
        let fireBaseMessaging = getFirebaseAppMessagingInstance();
        let swRegistration = await getSwRegistrationInstance(true);
        let getTokenOptions = {vapidKey: AppConstants.googleFireBaseVapidPublicKey, serviceWorkerRegistration: swRegistration};
        if (swRegistration === null || swRegistration === undefined) {
            getTokenOptions = {vapidKey: AppConstants.googleFireBaseVapidPublicKey};
        }
        let clientDeviceFireBaseToken = await getToken(fireBaseMessaging, getTokenOptions).then((currentToken) => {
            if (currentToken) {
                console.log('getFCMToken: ', currentToken);
                return currentToken;
            } else {
                if (typeof $?.jnoty !== 'undefined') {
                    AppUtils.showAlertMessage('Please allow browser notification permission.', 'warning');
                } else {
                    AppUtils.showToastMessage('Notice', 'Please allow browser notification permission.', 'warning');
                }
                return '';
            }
        }).catch((err) => {
            // AppUtils.showAlertMessage('An error occurred while getting device info: ' + err.message, 'warning');
            console.log('getToken catch error: ', err);
            return '';
        });

        returnData[clientDeviceID] = clientDeviceFireBaseToken;

        return (clientDeviceFireBaseToken !== '') ? returnData : {};
    } else {
        return returnData;
    }
};

/**
 * Retrieves the instance of the service worker registration
 * @returns {Promise<ServiceWorkerRegistration>}
 */
export const getSwRegistrationInstance = async (getInstance = false) => {
    try {
        if (getInstance) {
            return await navigator.serviceWorker.getRegistrations().then((registrations) => {
                return registrations.filter((reg) => reg?.active?.scriptURL?.includes('firebase-messaging-sw.js'))[0];
            });
        }

        let appURL = getScopeUrlByAppEnvironment();

        return await navigator.serviceWorker.register(appURL + '/firebase-messaging-sw.js', {scope: appURL + '/firebase-cloud-messaging-push-scope', type: 'classic', updateViaCache: 'imports'});

    } catch (e) {
        console.log('getSwRegistrationInstance error: ', e);
        return null;
    }
};

/**
 * Get the scope URL based on the app environment
 * @returns {*}
 */
const getScopeUrlByAppEnvironment = () => {
    // let appURL;
    // if (AppConstants.SALEPRO_APP_ENVIRONMENT === 'salepro-all') {
    //     appURL = AppConstants.SALEPRO_LIVE_SITE_URL;
    // } else if (AppConstants.SALEPRO_APP_ENVIRONMENT === 'salepro-admin') {
    //     appURL = AppConstants.SALEPRO_ADMIN_LIVE_SITE_URL;
    // } else if (AppConstants.SALEPRO_APP_ENVIRONMENT === 'salepro-client') {
    //     appURL = AppConstants.SALEPRO_CLIENT_LIVE_SITE_URL;
    // } else {
    //     appURL = window?.location?.origin;
    // }
    return window?.location?.origin;
};
