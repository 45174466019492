import * as AWS_AMPLIFY_CONFIG from "../../amplifyconfiguration.json";

import {Amplify} from "aws-amplify";
import {ConsoleLogger} from "aws-amplify/utils";

/**
 * Amplify Logger LOG_TYPES
 * DEBUG
 * ERROR
 * INFO
 * WARN
 * VERBOSE
 */
// ConsoleLogger.LOG_LEVEL = 'VERBOSE';


let amplifyConfigured = null;

/**
 * Log message with file name of amplify configuration
 * To see the logs in console, set show to true
 * @param message
 * @param fromFile
 * @param show
 */
function logMessage(message, fromFile, show = false) {
    if (show) {
        console.log(message, fromFile);
    }
}

/**
 * Configure AWS Amplify
 * @param fromFile
 * @returns {*}
 */
async function configureAwsAmplify(fromFile = '') {
    logMessage('=== configureAwsAmplify START ===', fromFile);

    if (!amplifyConfigured) {

        // logMessage('=== configureAwsAmplify: Amplify._config: ' + JSON.stringify(Amplify._config), fromFile);

        amplifyConfigured = await new Promise(async (resolve, reject) => {
            try {

                Amplify.configure(AWS_AMPLIFY_CONFIG);

                logMessage('=== AMPLIFY CONFIGURED!', fromFile);

                resolve(true);

            } catch (error) {

                logMessage('=== AMPLIFY CONFIGURATION ERROR:' + error.message, fromFile);

                reject(false);
            }
        });

    } else {
        logMessage('=== AMPLIFY ALREADY CONFIGURED!', fromFile);
    }

    logMessage('=== configureAwsAmplify END ===', fromFile);

    return amplifyConfigured;
}

/**
 * Ensure AWS Amplify is configured or not before using it
 * If not configured, it will configure it
 * @param fromFile
 * @returns {Promise<void>}
 */
export const ensureConfigured = async (fromFile = '') => {
    await configureAwsAmplify(fromFile);
};

/**
 * Override console log feature with this function.
 * Only DataStore string related logs will be shown only.
 * To see the DataStore related logs only just call this function below.
 */
const overrideConsoleLogWithDataStoreEvents = () => {

    ConsoleLogger.LOG_LEVEL = 'DEBUG';

    // Step 1: Save the original console.log function
    const originalConsoleLog = console.log;

    // Step 2: Define the specific string you want to filter by
    const filterString = 'DataStore';

    // Step 3: Override console.log
    console.log = function (...args) {
        // Check if the arguments contain the specific string
        if (args.some(arg => typeof arg === 'string' && arg.includes(filterString))) {
            // If the string is found, call the original console.log
            originalConsoleLog.apply(console, args);
        }
    };

    const originalConsoleWarn = console.warn;
    console.warn = function (...args) {
        if (args.some(arg => typeof arg === 'string' && arg.includes(filterString))) {
            originalConsoleWarn.apply(console, args);
        }
    };

    const originalConsoleError = console.error;
    console.error = function (...args) {
        if (args.some(arg => typeof arg === 'string' && arg.includes(filterString))) {
            originalConsoleError.apply(console, args);
        }
    };

    const originalConsoleInfo = console.info;
    console.info = function (...args) {
        if (args.some(arg => typeof arg === 'string' && arg.includes(filterString))) {
            originalConsoleInfo.apply(console, args);
        }
    };
};

// overrideConsoleLogWithDataStoreEvents();